<template>
  <!-- 添加企业信息 -->
  <div class="p-l-r20">
    <van-field label-width="0" v-model="companyName" placeholder="请输入公司名称或关键字" />
    <div class="mt6">
      <div v-for="(item, index) in list" :key="index" class="companyName">
        <span>{{ item.name }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { Field, Cell, Button } from 'vant'

Vue.use(Field)
Vue.use(Cell)
Vue.use(Button)

export default {
  data() {
    return {
      companyName: '',
      list: [
        { name: '珠海民商保理有限公司' },
        { name: '珠海民商鸿博科技有限公司' },
        { name: '珠海民商企业管理有限公司' },
        { name: '珠海民商互联网金融大厦开发有限公司' },
      ],
    }
  },
  created() {},
  computed: {},
  methods: {},
}
</script>
<style lang="less" scoped>
.van-cell {
  padding: 0;
  padding: 0.3rem 0;
  border-bottom: 1px solid #e6e6e6;
}
.companyName {
  padding: 0.3rem 0 0.26rem;
  border-bottom: 0.02rem #e6e6e6 solid;
}
</style>
